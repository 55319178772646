import axios from 'axios';

export default {
    search(params) {
        return axios.post(window.API_BASE + '/investors/search', params);
    },

    get(id) {
        return axios.get(window.API_BASE + '/investors/' + id);
    },

    approve(id) {
        return axios.post(window.API_BASE + '/investors/' + id + '/approve');
    },

    addAccount(id, params) {
        return axios.post(window.API_BASE + '/investors/' + id + '/add-account', params);
    }
}